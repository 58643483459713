import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SliderSection from '../../shared/SliderSection/SliderSection';

const HumanFeature = () => {
  const {
    homepageJson: { humanFeature },
  } = useStaticQuery(graphql`
    {
      homepageJson {
        humanFeature {
          id
          shapePath
          text
          image {
            src {
              id
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
            flair {
              top
              left
              rotate
            }
          }
        }
      }
    }
  `);

  return <SliderSection type="left" sliderContent={humanFeature} />;
};

export default HumanFeature;
