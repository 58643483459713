import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';
import SliderSidebar from '../../shared/SliderSidebar/SliderSidebar';
import useFeatureDescription from '../../../utils/useFeatureDescription';

import useSlider from '../../../utils/useSlider';

import DocIcon from '../../../icons/doc.inline.svg';

import './HowItWorks.scss';

const HowItWorks = () => {
  const {
    homepageJson: { howItWorks },
  } = useStaticQuery(graphql`
    {
      homepageJson {
        howItWorks {
          content
          id
          title
          icon {
            publicURL
          }
          demo {
            shapePath
            thumbnail {
              position
              src {
                childImageSharp {
                  fixed(width: 64, height: 64) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            content {
              type
              src {
                id
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { isDesktopView } = useFeatureDescription();
  const featureList = howItWorks.map(elements => ({
    id: elements.id,
    title: elements.title,
    content: elements.content,
    icon: elements.icon.publicURL,
    collapsable: true,
  }));

  const demoList = howItWorks.map(elements => ({
    ...elements.demo,
    id: elements.id,
  }));

  const {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  } = useSlider(featureList.length, 4000);

  const sectionDescription =
    'Edwin is built around creating human behavior change, taking micro steps to create better habits that directly impact your security.';

  return (
    <div ref={sliderElementRef} className="how-it-works">
      <div className="container">
        <HeaderSection
          icon={<DocIcon />}
          subTitle="How it Works"
          title="A platform dedicated to keeping you secure."
          description={!isDesktopView ? sectionDescription : ''}
        />
      </div>
      <FeatureSection
        isReversed
        rowViewOnTablet
        firstColumn={
          <DemoSection demos={demoList} activeElement={activeSlideId} />
        }
        secondColumn={
          <SliderSidebar
            description={isDesktopView ? sectionDescription : ''}
            boxes={featureList}
            collapsable={false}
            activeElementId={activeSlideId}
            setActiveElement={setSlide}
            columnViewOnTablet={false}
            progressValue={progressValue}
            stopAutoPlay={stopAutoPlay}
          />
        }
      />
    </div>
  );
};

export default HowItWorks;
