import { useEffect, useState } from 'react';

const useFeatureDescription = () => {
  const [isDesktopView, setDesktopView] = useState(true);

  const handleResize = () => {
    if (window.innerWidth >= 1240) {
      setDesktopView(true);
    } else {
      setDesktopView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return { isDesktopView };
};

export default useFeatureDescription;
