import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Header from '../components/Header/Header';
import IntroSection from '../components/Homepage/IntroSection/IntroSection';
import WhatWeDo from '../components/Homepage/WhatWeDo/WhatWeDo';
import FirstQuote from '../components/Homepage/FirstQuote/FirstQuote';
import HumanFeature from '../components/Homepage/HumanFeature/HumanFeature';
import SecondQuote from '../components/Homepage/SecondQuote/SecondQuote';
import TheEssence from '../components/Homepage/TheEssence/TheEssence';
import OurCommitment from '../components/Homepage/OurCommitment/OurCommitment';
import HowItWorks from '../components/Homepage/HowItWorks/HowItWorks';
import ThirdQuote from '../components/Homepage/ThirdQuote/ThirdQuote';
import Customers from '../components/shared/Customers/Customers';
import BookADemo from '../components/shared/BookADemo/BookADemo';
import useScroll from '../utils/useScroll';

const HomePage = () => {
  const [scrollToBookADemo, bookADemoComponent] = useScroll();
  const [scrollWhatWeDo, whatWeDoComponent] = useScroll();

  return (
    <Layout>
      <Header demoButtonNavigation="/book-demo" />
      <Seo
        title="Edwin - Cybersecurity designed for humans"
        description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
      />
      <IntroSection
        scrollToBookADemo={scrollToBookADemo}
        scrollWhatWeDo={scrollWhatWeDo}
      />
      <WhatWeDo refToElement={whatWeDoComponent} />
      <FirstQuote />
      <TheEssence />
      <HumanFeature />
      <OurCommitment />
      <SecondQuote />
      <HowItWorks />
      <ThirdQuote />
      <Customers />
      <BookADemo refToElement={bookADemoComponent} />
    </Layout>
  );
};

export default HomePage;
