import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';

import phone from '../../../video/video.mov';

import PromiseIcon from '../../../icons/promise.inline.svg';
import ScopeIcon from '../../../icons/scope.inline.svg';
import ClockIcon from '../../../icons/clock.inline.svg';
import CheckIcon from '../../../icons/check.inline.svg';
import PersonIcon from '../../../icons/person.inline.svg';

import './OurCommitment.scss';

const boxes = [
  {
    icon: <ScopeIcon />,
    title: 'How we engage',
    content:
      'We use a simple conversational interface to prompt and engage users. Integrating with familiar workflow environments like email, Slack, and chat makes engagement easy, fun, and simple to do.',
  },
  {
    icon: <ClockIcon />,
    title: 'How we motivate',
    content:
      'In behavior change, motivation is overrated. We make actions short, simple, relevant and fun. We then repeat them so they build into long term habits.',
  },
  {
    icon: <CheckIcon />,
    title: 'How we verify',
    content:
      'We ask users to “oath” that they have performed security behaviors, and then verify the oath with actual proof. These form an audit trail that users own and build on over time.',
  },
  {
    icon: <PersonIcon />,
    title: 'How we personalize',
    content:
      'We differentiate between competent and novice users and adjust the experience accordingly. Already an expert? Just prove you’re already secure and move on.',
  },
];

const renderInfoBox = box => (
  <div key={box.title} className="our-commitment__box">
    {box.icon}
    <h3>
      <strong>{box.title}</strong>
    </h3>
    <p>{box.content}</p>
  </div>
);

const OurCommitment = () => {
  const thumbnail = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "rectangles/07.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const demoList = [
    {
      id: 0,
      thumbnail: {
        position: 'right',
        src: thumbnail.file,
      },
      shapePath:
        'M568.999 199.743C616.829 284.119 500.974 468.875 370.586 522.477C259.403 568.172 96.9317 524.894 30.6912 387.986C-79.8323 159.635 130.956 -99.7949 300.878 38.906C437.856 150.716 534.047 125.992 568.999 199.743Z',
      content: {
        src: {
          element: (
            <video playsInline autoPlay muted loop>
              <source src={phone} type="video/mp4" />
            </video>
          ),
          extension: 'mov',
        },
        type: 'box',
      },
    },
  ];

  return (
    <div className="our-commitment">
      <div className="container">
        <HeaderSection
          icon={<PromiseIcon />}
          subTitle="Our Commitment"
          title="Experience that drives engagement"
          description="We don’t believe in using fear to change human behavior. Rather, our platform engages  people with facts and choices, impacting behavior, mindset, and culture— not just security awareness."
        />
      </div>
      <FeatureSection
        isReversed
        rowViewOnTablet={false}
        firstColumn={<DemoSection demos={demoList} activeElement={0} />}
        secondColumn={
          <div className="our-commitment__boxes">
            {boxes.map(renderInfoBox)}
          </div>
        }
      />
    </div>
  );
};

export default OurCommitment;
