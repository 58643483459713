import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import SliderFlair from '../../../icons/sliderFlair.inline.svg';

const ImageSection = ({ image, shapePath }) => (
  <>
    <div
      className="intro-section__slider-flair"
      style={{
        top: `4%`,
        left: `16%`,
        transform: `rotate(-18deg)`,
      }}
    >
      <SliderFlair />
    </div>
    <Img
      className="intro-section__image"
      fluid={image}
      style={{ maxWidth: image.presentationWidth }}
    />
    <svg
      width="610"
      height="568"
      viewBox="0 0 610 568"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="intro-section__shape"
    >
      <path d={shapePath} fill="#0DB876" />
    </svg>
  </>
);

ImageSection.propTypes = {
  image: PropTypes.shape({
    presentationWidth: PropTypes.number,
  }).isRequired,
  shapePath: PropTypes.string.isRequired,
};

export default ImageSection;
