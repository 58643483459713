import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon1 from '../../../icons/ilustration_04.inline.svg';
import shapePaths from '../../../utils/shapePaths';

const FirstQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/06.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <QuoteSection
      content={
        <span>
          “We&#x27;ve designed bite-sized missions that build on what you
          already know to create lasting behavior changes with huge impact on
          security.”
        </span>
      }
      shapePath={shapePaths[3]}
      author={{
        name: 'Amit Lubling',
        title: 'Edwin Co-Founder & CEO',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon1 />}
    />
  );
};

export default FirstQuote;
