import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';
import SliderSidebar from '../../shared/SliderSidebar/SliderSidebar';
import useSlider from '../../../utils/useSlider';

import ThumbprintIcon from '../../../icons/thumbprint.inline.svg';

import './WhatWeDo.scss';

const WhatWeDo = ({ refToElement }) => {
  const {
    homepageJson: { whatWeDo },
  } = useStaticQuery(graphql`
    {
      homepageJson {
        whatWeDo {
          content
          id
          title
          demo {
            shapePath
            thumbnail {
              position
              src {
                childImageSharp {
                  fixed(width: 64, height: 64) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            content {
              type
              src {
                id
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const featureList = whatWeDo.map(elements => ({
    id: elements.id,
    title: elements.title,
    content: elements.content,
  }));

  const demoList = whatWeDo.map(elements => ({
    ...elements.demo,
    id: elements.id,
  }));

  const {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  } = useSlider(featureList.length, 4000);

  return (
    <div ref={refToElement} className="what-we-do">
      <div ref={sliderElementRef} className="container">
        <HeaderSection
          icon={<ThumbprintIcon />}
          subTitle="What We DO"
          title="We help organizations achieve security best practices through human behavior"
        />
      </div>
      <FeatureSection
        rowViewOnTablet={false}
        firstColumn={
          <DemoSection demos={demoList} activeElement={activeSlideId} />
        }
        secondColumn={
          <SliderSidebar
            boxes={featureList}
            collapsable={false}
            activeElementId={activeSlideId}
            setActiveElement={setSlide}
            columnViewOnTablet
            progressValue={progressValue}
            stopAutoPlay={stopAutoPlay}
          />
        }
      />
    </div>
  );
};

WhatWeDo.propTypes = {
  refToElement: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

export default WhatWeDo;
