import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Button from '../../shared/Button/Button';
import shapePaths from '../../../utils/shapePaths';

import ImageSection from './ImageSection';
import './IntroSection.scss';

const IntroSection = ({ scrollWhatWeDo, scrollToBookADemo }) => {
  const sectionImage = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "intro-image.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <div className="intro-section">
      <div className="container intro-section__container">
        <div className="intro-section__left-column">
          <h1>
            <span className="text-main-color">Cybersecurity</span>
            <br /> designed for humans.
          </h1>
          <p className="text-second-color">
            Edwin is a radically new approach to cybersecurity that’s designed
            with human behavior change at its core.
          </p>
          <div className="intro-section__buttons-wrapper">
            <Button
              fluid
              onClick={scrollToBookADemo}
              type="secondary"
              className="intro-section__button"
            >
              Book a Demo
            </Button>
            <Button
              fluid
              onClick={scrollWhatWeDo}
              type="primary"
              className="intro-section__button"
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="intro-section__image-wrapper">
          <ImageSection
            image={sectionImage.file.childImageSharp.fluid}
            shapePath={shapePaths[0]}
          />
        </div>
      </div>
    </div>
  );
};

IntroSection.propTypes = {
  scrollWhatWeDo: PropTypes.func.isRequired,
  scrollToBookADemo: PropTypes.func.isRequired,
};

export default IntroSection;
