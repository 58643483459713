import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SliderTextBox from '../SliderTextBox/SliderTextBox';

import './SliderSidebar.scss';

const SliderSidebar = ({
  boxes,
  className,
  columnViewOnTablet,
  activeElementId,
  setActiveElement,
  description,
  stopAutoPlay,
  progressValue,
}) => {
  const rendeBoxes = box => (
    <div
      role="button"
      tabIndex="0"
      className="slider-sidebar__box"
      key={box.id}
      onClick={() => {
        setActiveElement(box.id);
        stopAutoPlay(true);
      }}
      onKeyPress={() => {
        setActiveElement(box.id);
        stopAutoPlay(true);
      }}
    >
      <SliderTextBox
        className={box.className}
        isActive={activeElementId === box.id}
        scrollBarHeight={box.scrollBarHeight}
        collapsable={box.collapsable}
        columnViewOnTablet={columnViewOnTablet}
        icon={box.icon}
        title={box.title}
        content={box.content}
        link={box.link}
        stopAutoPlay={stopAutoPlay}
        progressValue={activeElementId === box.id ? progressValue : 0}
      />
    </div>
  );

  return (
    <div
      className={classnames(
        'slider-sidebar',
        { 'slider-sidebar--columns': columnViewOnTablet },
        className,
      )}
    >
      {description && (
        <div className="slider-sidebar__description">{description}</div>
      )}
      {boxes.map(rendeBoxes)}
    </div>
  );
};

SliderSidebar.defaultProps = {
  columnViewOnTablet: false,
  className: '',
  activeElementId: 0,
  setActiveElement: () => {},
  description: '',
};

SliderSidebar.propTypes = {
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      isActive: PropTypes.bool,
      columnViewOnTablet: PropTypes.bool,
      scrollBarHeight: PropTypes.number,
      icon: PropTypes.node,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      link: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeElementId: PropTypes.number,
  setActiveElement: PropTypes.func,
  columnViewOnTablet: PropTypes.bool,
  stopAutoPlay: PropTypes.func.isRequired,
  progressValue: PropTypes.number.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default SliderSidebar;
