import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';
import SliderSidebar from '../../shared/SliderSidebar/SliderSidebar';
import useSlider from '../../../utils/useSlider';

import LightIcon from '../../../icons/light.inline.svg';

import useFeatureDescription from '../../../utils/useFeatureDescription';

import './TheEssence.scss';

const TheEssence = () => {
  const {
    homepageJson: { theEssence },
  } = useStaticQuery(graphql`
    {
      homepageJson {
        theEssence {
          content
          id
          title
          icon {
            publicURL
          }
          demo {
            shapePath
            thumbnail {
              position
              src {
                childImageSharp {
                  fixed(width: 64, height: 64) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            content {
              type
              src {
                id
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { isDesktopView } = useFeatureDescription();

  const featureList = theEssence.map(elements => ({
    id: elements.id,
    title: elements.title,
    content: elements.content,
    icon: elements.icon.publicURL,
    collapsable: true,
  }));

  const demoList = theEssence.map(elements => ({
    ...elements.demo,
    id: elements.id,
  }));

  const {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  } = useSlider(featureList.length, 4000);

  const sectionDescription =
    'The goal of every Edwin “mission” is to help people practice a security action that boosts confidence and builds competence so they can easily perform the behaviors that enhance security for themselves, their families, and their organizations.';

  return (
    <div ref={sliderElementRef} className="the-essence">
      <div className="container">
        <HeaderSection
          icon={<LightIcon />}
          subTitle="The Essence"
          title="Practice that drives behavior change"
          description={!isDesktopView ? sectionDescription : ''}
        />
      </div>
      <FeatureSection
        isReversed
        rowViewOnTablet
        firstColumn={
          <DemoSection demos={demoList} activeElement={activeSlideId} />
        }
        secondColumn={
          <SliderSidebar
            description={isDesktopView ? sectionDescription : ''}
            boxes={featureList}
            collapsable={false}
            activeElementId={activeSlideId}
            setActiveElement={setSlide}
            columnViewOnTablet={false}
            stopAutoPlay={stopAutoPlay}
            progressValue={progressValue}
          />
        }
      />
    </div>
  );
};

export default TheEssence;
