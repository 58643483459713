import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon1 from '../../../icons/ilustration_02.inline.svg';
import shapePaths from '../../../utils/shapePaths';

import './ThirdQuote.scss';

const ThirdQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/07.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <QuoteSection
      className="steven-quote"
      type="left"
      content={
        <span>
          “Edwin provides an easy, practical, and habit-forming way to{' '}
          <span className="text-third-color">help organizations change </span>{' '}
          not just what they know about cybersecurity, but what they do.”
        </span>
      }
      shapePath={shapePaths[7]}
      author={{
        name: 'Steven Dean',
        title: 'Co-Founder & Head of Behavioral Science',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon1 />}
    />
  );
};

export default ThirdQuote;
