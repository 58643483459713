import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';

import Arrow from '../../../icons/arrow.inline.svg';

import './SliderTextBox.scss';

const SliderTextBox = ({
  className,
  columnViewOnTablet,
  isActive,
  collapsable,
  icon,
  title,
  content,
  link,
  stopAutoPlay,
  progressValue,
}) => {
  const [isTablet, setIsTablet] = useState(false);

  const handleResize = () => {
    setIsTablet(window.innerWidth < 1240 && window.innerWidth > 767);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div
      className={classnames(
        'slider-text-box',
        className,
        { 'slider-text-box--active': isActive },
        { 'slider-text-box--collapsable': collapsable },
        { 'slider-text-box--tablet-columns': columnViewOnTablet },
      )}
      onMouseEnter={() => stopAutoPlay(isActive && true)}
      onMouseLeave={() => stopAutoPlay(isActive && false)}
    >
      <div className="slider-text-box__scroll-bar">
        <div
          className="slider-text-box__scroll"
          style={{
            height:
              isTablet && columnViewOnTablet ? '4px' : `${progressValue}%`,
            width: isTablet && columnViewOnTablet ? `${progressValue}%` : '4px',
          }}
        />
      </div>
      <div className="slider-text-box__wrapper">
        <h3>
          {icon && (
            <span className="slider-text-box__icon">
              <img src={icon} alt={title} />
            </span>
          )}
          {title}
        </h3>
        <p className="slider-text-box__content text-body-14">{content}</p>
      </div>
      {link && (
        <Link className="slider-text-box__link" to={link.url}>
          <Arrow />
          {link.name}
        </Link>
      )}
    </div>
  );
};

SliderTextBox.defaultProps = {
  className: '',
  isActive: false,
  collapsable: false,
  columnViewOnTablet: false,
  icon: undefined,
  link: null,
};

SliderTextBox.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  collapsable: PropTypes.bool,
  columnViewOnTablet: PropTypes.bool,
  stopAutoPlay: PropTypes.func.isRequired,
  progressValue: PropTypes.number.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default SliderTextBox;
