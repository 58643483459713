import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';

import QuoteIcon2 from '../../../icons/ilustration_01.inline.svg';
import shapePaths from '../../../utils/shapePaths';

import './SecondQuote.scss';

const SecondQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/08.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <QuoteSection
      className="john-quote"
      type="left"
      content={
        <span>
          “Based on the UK’s Cyber Essentials and the US’s NIST framework,
          Edwin’s
          <span className="text-third-color">
            {' '}
            ‘missions’ help change the security behaviors{' '}
          </span>
          that impact people, their families, and their organizations.”
        </span>
      }
      shapePath={shapePaths[3]}
      author={{
        name: 'John Coursen',
        title: 'Edwin Co-Founder & Chief Security Officer',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon2 />}
    />
  );
};

export default SecondQuote;
